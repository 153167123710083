// Here you can add other styles
body {
  font-size: 0.675rem;
}

.c-app,
.c-header,
.c-subheader,
.c-footer {
  background: $primary-100;
}

.logo-main {
  font-size: 60px;
  width: 220px;
}
.logo-header {
  width: 160px;
}
.kja-style {
  height: 88px;
}
.logo-small {
  height: 80px;
  width: 80px;
}

.edit-sticky {
  z-index: 9999;
  width: calc(100% - 320px);
  background: white;
  top: 105px;
  left: 320px;
  padding: 5px 33px;

  &-label {
    line-height: 2.5;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg-526B6B {
  background-color: #526b6b;
  a {
    background-color: #526b6b;
  }
}

.sr-filters-form {
  select {
    @media screen and (min-width: 768px) {
      max-width: 180px;
    }
  }
}

select.filter {
  min-width: 200px;
}

[to$="reporting"] {
  .c-sidebar-nav-dropdown-toggle {
    display: none;
  }
}

html:not([dir="rtl"]) [to$="kja-administration"] {
  .c-sidebar-nav-dropdown-toggle {
    display: none;
  }
  .c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
    background-color: $primary-400;
  }
}

.c-sidebar {
  width: 320px;
}

@media (min-width: 992px) {
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 320px;
  }
}

.table-resizable {
  &.score-card {
    td {
      font-size: 0.556rem;
      padding: 0.2rem;
      vertical-align: top;
    }
  }

  &.resizing {
    cursor: col-resize;
    user-select: none;
  }

	td {
		position: relative;
        padding: .2rem;

        vertical-align: middle;
		// Show resize curson when hovering over column borders
		&::after {
			@extend .table-resizable.resizing;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			width: 1em;
		}

    &:last-of-type::before {
      display: none;
    }
  }

  // Add `th` to the selector below to allow shrinking a column all the way
  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

#columnclient {
  text-indent: 10px;
}
.score-card-special-row,
.primary-row {
  background: #156c6c !important;
  color: #fff;

  &.opacity-70 {
    opacity: 0.7;
  }

  &:hover {
    color: #fff !important;
  }

  &-2 {
    background: #526b6b !important;
    color: #fff;

    &:hover {
      color: #fff !important;
    }
  }
}
.secondary-row td:first-child {
  background: #ccc !important;
  color: #000;
  text-indent: 20px !important;

  &.opacity-70 {
    opacity: 0.7;
  }

  &:hover {
    color: #000 !important;
  }

  &-2 {
    background: #526b6b !important;
    color: #fff;

    &:hover {
      color: #fff !important;
    }
  }
}
.primary-row {
  height: 50px;
}
.secondary-client td:first-child {
  text-indent: 30px !important;
}

.mini-icon {
  content: "";
  position: absolute;
  right: 5px;
  top: calc(50% - 5px);
  display: block;
  width: 10px;
  height: 10px;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.gte_50 {
  background: #fcd5b4;
  &-mini {
    position: relative;
    &:before {
      @extend .mini-icon;
      background-color: #fcd5b4;
    }
  }
}


.gte_60 {
  background: #feff99;
  &-mini {
    position: relative;
    &:before {
      @extend .mini-icon;
      background-color: #feff99;
    }
  }
}


.gte_85 {
  background: #d8e4be;
  &-mini {
    position: relative;
    &:before {
      @extend .mini-icon;
      background-color: #d8e4be;
    }
  }
}


.gte_90 {
  background: #c3d79c;
  &-mini {
    position: relative;
    &:before {
      @extend .mini-icon;
      background-color: #c3d79c;
    }
  }
}


.lte_50 {
  background: #da9695;
  &-mini {
    position: relative;
    &:before {
      @extend .mini-icon;
      background-color: #da9695;
    }
  }
}


.w-150px {
  width: 150px;
}

#office {
  display: table-cell;
}

.drilldown-report-table {
  td,
  th {
    white-space: normal;
  }
}

@media screen and (min-width: 768px) {
  .table th,
  .table td {
    overflow-wrap: anywhere;
  }
}

#column-th-edit div {
  display: none !important;
}

table {
  width: auto;
  -webkit-box-flex: 1;
  flex: 1;
}

html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -320px;
}

.settings-pos {
  vertical-align: bottom;
  position: relative;
  top: 4px;
.drop-zone {
  background: #f0f2f5;
  border: 2px dashed #ccc;
  border-radius: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;


    &.drag-over {
      background: #9ff3c2;
      border: 2px dashed rgb(115, 117, 116);
    }

    .drop-zone-all {
      &:focus {
        outline: 0;
      }
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 0;
    }
  }
}
.disabled {
  cursor: not-allowed;
}

.dimmed {
  opacity: 0.5;
}

.w-33 {
  width: 33% !important;
}

.widget-from {
  position: relative;
  padding-bottom: 10px;
  .invalid-feedback {
    position: absolute;
    bottom: 0;
  }
}

.w-200 {
  width: 200px;
}

th,
td {
  min-width: 150px;
}

.mw-20 {
  min-width: 200px;
}

.invalid .invalid-feedback {
  display: block;
}

.column-th-graph,
td.graph {
  min-width: 50px !important;
  width: 50px !important;
}

#columnedit {
  .btn {
    padding: 0 0.2rem;

    .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
      width: 0.65rem;
      height: 0.65rem;
      font-size: 0.65rem;
    }
  }
}

.column-th-graph,
td.graph {
  min-width: 50px !important;
  width: 50px !important;
}

.toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-top: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  margin-left: 10px;
}

.toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.toggle-check,
.toggle-uncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.toggle-check {
  left: 8px;
}
.toggle-uncheck {
  opacity: 1;
  right: 10px;
}

.toggle-uncheck span,
.toggle-check span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  position: relative;
  width: 10px;
  font-size: 18px;
  opacity: 0;
}

.toggle-container {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #f0f2f5;
  transition: all 0.2s ease;
  border: 1px solid #cfcfcf;
}

.toggle-circle {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: relative;
  top: -23px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #adadad;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.toggle--checked .toggle-check {
  opacity: 1;
}
.toggle--checked .toggle-uncheck {
  opacity: 0;
}
.toggle--checked .toggle-circle {
  left: 27px;
  background-color: #2cd945;
}

.toggle-title {
  font-size: 13px;
}

.toggler {
  margin-left: 15px;
}

ul.no-bullets {
  list-style-type: none;
}

.form-file-input {
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(60, 75, 100);
  border-bottom: 0px none rgb(60, 75, 100);
  border-color: rgb(60, 75, 100);
  border-image: none;
  border-left: 0px none rgb(60, 75, 100);
  border-right: 0px none rgb(60, 75, 100);
  border-style: none;
  border-top: 0px none rgb(60, 75, 100);
  border-width: 0px;
  box-sizing: border-box;
  color: rgb(60, 75, 100);
  cursor: auto;
  display: inline-block;
  font: normal normal normal normal 14px/28px barlow, sans-serif;
  font-variant: normal;
  height: 33px;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  padding: 0px;
  place-items: baseline normal;
  text-align: start;
  text-indent: 0px;
  text-shadow: none;
  text-transform: none;
  width: 308px;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
}

.eighty-percent-wide {
  width: 80%;
}

.ten-percent-wide {
  width: 10%;
}

.current-file {
  margin-bottom: 20px;
}

.select-search {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20px;
}

.select-search input {
  width: 80%;
  height: 34px;
}

.select-search ul {
  padding-left: 0;
  text-indent: 5px;
}

.select-search ul:first-child {
  width: 80%;
  border: 1px solid #ddd;
}

.select-search ul ul {
  border: 0 !important;
}

.select-search li {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  list-style-type: none;
}
.select-search__group-header {
  background: #f0f2f5;
  padding-top: 5px;
  padding-bottom: 5px;
}

.select-search button {
  border: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background: #fff;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.select-search button:hover {
  cursor: pointer;
}

.red {
  color: red;
}

.spacer10 {
  margin: 10px;
}

.table-small-min-width {
  min-width: 101%;
  max-width: 0;
  width: auto;
  th,
  td {
    min-width: 45px;
  }
  th {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th div.d-inline {
    max-width: 0;
    min-width: 25px;
  }
  th input {
    max-width: 0;
    min-width: 40px;
  }
}

.rs-picker-daterange-menu.rs-picker-menu {
  z-index: 999;
}

.sync {
  padding-left: 5px;
  padding-bottom: 5px;
}

.order-2 {
  order: 2;
}

.MuiTableCell-root.CellLayout-cellDraggable {
  overflow: hidden !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.c-sidebar-nav-link {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
}

svg.c-sidebar-nav-icon {
  flex: 0 0 30px;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $primary-200;
}

/* for Status Widgets */
.downtime {
  background: #002d72;
  color: #fff;
}

.downtime h1 {
  font-weight: 600;
  font-size: 1.2rem;
  color: #fff;
}

.downtime a {
  color: #fff;
}

.downtime p {
  font-size: 0.8rem;
}

.downtime.card-body {
  color: #fff;
  min-height: 125px;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 3px;
  border: 2px solid #ddd;
}

.downtime.red {
  background: #700;
}

.downtime.green {
  background: #007200;
}

.downtime.status {
  min-height: 113px;
}

.squish {
  display: none !important;
}

.flatten {
  padding: 0 !important;
  margin: 0 !important;
}

.flex-me {
  display: flex;
  flex: 1;
}

.status-row {
  margin-right: 200px;
  margin-bottom: 5px;
  user-select: none;
}

.status-item {
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  line-height: 1.5;
}

p.widget-caption {
  width: 100%;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.btn-top {
  margin-top: 30px;
}

/* End for Status Widgets */

.w-25 {
  width: 25% !important;
}

.hidden {
  display: none;
}

.card {
  padding-bottom: 0 !important;
}

.overview h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.form-control,
.btn {
  font-size: 0.675rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #052c46;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: #052c46 !important;
}

a {
  color: #052c46;
}

.table td {
  padding: 0.2rem;
  vertical-align: middle;
}

.table th {
  padding: 0.4rem;
}
.table.table-tr-middle td {
  vertical-align: middle;
}

button.hide {
  visibility: hidden;
}

.devices-list-table .card-header {
  & input.form-control.w-25 {
    order: 0
  }

  & button.btn {
    order: 1
  }

}
.card-header-right {
  position: absolute;
  right: 20px;
  top: 15px;
}
.kjadatatable-extra-header-btn {
  margin-right: -20px;
}

.card-heading-absolute {
  display: inline-block;
  position: absolute;
  z-index: 5;
  top: 35px;
  left: 20px;
}
.card-btn-absolute-right {
  display: inline-block;
  position: absolute;
  z-index: 5;
  top: 15px;
  right: 20px;
}
.form-group.flex-center {
  display: flex;
  align-items: center;
  & label {
    margin-bottom: 0;
  }
  & label.mr-small {
    margin-right: 5px;
  }
  & label.form-label-5 {
    min-width: 50px;
  }
}
.tab-pane {
  position: relative;
  .tab-action-btns {
    position: absolute;
    top: 40px;
    left: 10px;
    z-index: 10;
  }
}
/* Custom styles for the dropdown */
.dropdown-menu.custom-dropdown {
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
}

.dropdown-menu.custom-dropdown .dropdown-header {
  font-size: 12px;
  color: #6c757d;
  font-weight: bold;
  text-transform: uppercase;
}
.tab-pane {
  position: relative;
  .tab-action-btns {
    position: absolute;
    top: 40px;
    left: 10px;
    z-index: 10;
  }
}
/* Custom styles for the dropdown */
.dropdown-menu.custom-dropdown {
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
}

.dropdown-menu.custom-dropdown .dropdown-header {
  font-size: 12px;
  color: #6c757d;
  font-weight: bold;
  text-transform: uppercase;
}

.dropdown-menu.custom-dropdown .dropdown-item {
  padding: 8px 12px;
  white-space: nowrap;
  font-size: 12px;
  margin-left: 10px;
}

.dropdown-menu.custom-dropdown .dropdown-item:hover {
  background-color: #f1f1f1; /* Slight highlight on hover */
}
button.hide {
  visibility: hidden;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #ebebeb;
    opacity: 1;
}
.custom-select:disabled {
    background-color: #ebebeb;
    opacity: 1;
}
.date-picker-form-group {
  margin-top: -5px;
}
.rs-picker-disabled {
    opacity: 1 !important;
}
.rs-picker-default.rs-picker-disabled .rs-btn, .rs-picker-default.rs-picker-disabled .rs-picker-toggle {
    background-color: #ebebeb !important;
}
.flex.flex-end {
  display: flex;
  justify-content: end;
}
.table-responsive .table {
    max-width: none;
    -webkit-overflow-scrolling: touch !important;
}
