
// stylelint-disable selector-max-class, declaration-no-important, selector-max-compound-selectors, function-url-quotes, selector-max-combinators
.c-sidebar {
    position: relative;
    display: flex;
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    flex-direction: column;
    // put the nav on the left
    order: -1;
    width: $sidebar-width;
    padding: $sidebar-padding;
    box-shadow: none;
    font-size: 0.6;
  
    &.c-sidebar-right {
      order: 99;
    }
  
    @include media-breakpoint-down(breakpoint-before($mobile-breakpoint)) {
      // Some of our components use this property to detect if the sidebar has mobile behavior.
      --is-mobile: true;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: $zindex-fixed + 1;
  
      @include ltr {
        &:not(.c-sidebar-right){
          left: 0;
        }
        &.c-sidebar-right {
          right: 0;
        }
      }
      @include rtl {
        &:not(.c-sidebar-right){
          right: 0;
        }
        &.c-sidebar-right {
          left: 0;
        }
      }
    }
  
    @include ltr {
      &:not(.c-sidebar-right){
        margin-left: - $sidebar-width;
      }
      &.c-sidebar-right {
        margin-right: - $sidebar-width;
      }
    }
  
    @include rtl {
      &:not(.c-sidebar-right){
        margin-right: - $sidebar-width;
      }
      &.c-sidebar-right {
        margin-left: - $sidebar-width;
      }
    }
  
    &[class*="bg-"]  {
      border-color: rgba($black, .1);
    }
  
    @each $width, $value in $sidebar-widths {
      &.c-sidebar-#{$width} {
        flex: 0 0 $value;
        width: $value;
  
        @include ltr {
          &:not(.c-sidebar-right){
            margin-left: - $value;
          }
          &.c-sidebar-right {
            margin-right: - $value;
          }
        }
  
        @include rtl {
          &:not(.c-sidebar-right){
            margin-right: - $value;
          }
          &.c-sidebar-right {
            margin-left: - $value;
          }
        }
      }
    }
  
    &.c-sidebar-fixed {
      @include media-breakpoint-up($mobile-breakpoint) {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: $zindex-fixed;
  
        @include ltr {
          &:not(.c-sidebar-right) {
            left: 0;
          }
  
          &.c-sidebar-right {
            right: 0;
          }
        }
  
        @include rtl {
          &:not(.c-sidebar-right) {
            right: 0;
          }
  
          &.c-sidebar-right {
            left: 0;
          }
        }
      }
    }
  
    &.c-sidebar-overlaid {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: $zindex-fixed + 2;
  
      @include ltr {
        &:not(.c-sidebar-right) {
          left: 0;
        }
  
        &.c-sidebar-right {
          right: 0;
        }
      }
  
      @include rtl {
        &:not(.c-sidebar-right) {
          right: 0;
        }
  
        &.c-sidebar-right {
          left: 0;
        }
      }
    }
  }
  
  //
  // Sidebar components
  //
  
  .c-sidebar-close {
    position: absolute;
    width: $sidebar-brand-height;
    height: $sidebar-brand-height;
    background: transparent;
    border: 0;
  
    @include hover() {
      text-decoration: none;
    }
  
    &:focus,
    &.focus {
      outline: 0;
    }
  
    @include ltr {
      right: 0;
    }
  
    @include rtl {
      left: 0;
    }
  }
  
  .c-sidebar-brand {
    display: flex;
    flex: 0 0 $sidebar-brand-height;
    align-items: center;
    justify-content: center;
  
    .c-sidebar-brand-minimized {
      display: none;
    }
  }
  
  .c-sidebar-header {
    flex: 0 0 $sidebar-header-height;
    padding: $sidebar-header-padding-y $sidebar-header-padding-x;
    text-align: center;
    transition: $layout-transition-speed;
  }
  
  // Sidebar navigation
  
  .c-sidebar-nav {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
  
    &.ps {
      // Firefox
      overflow: -moz-scrollbars-none;
      // IE 10+
      -ms-overflow-style: none;
      // Chrome, Edge, Safari, Opera
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
  }
  
  .c-sidebar-nav-title {
    padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
    margin-top: $sidebar-nav-title-margin-top;
    font-size: 80%;
    font-weight: 700;
    text-transform: uppercase;
    transition: $layout-transition-speed;
  }
  
  .c-sidebar-nav-divider {
    height: 10px;
    transition: height $layout-transition-speed;
  }
  
  .c-sidebar-nav-item {
    width: inherit;
  }
  
  .c-sidebar-nav-link {
    display: flex;
    flex: 1;
    align-items: center;
    padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
    text-decoration: none;
    white-space: nowrap;
    transition: background $layout-transition-speed, color $layout-transition-speed;
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }
  
    .badge {
      @include ltr {
        margin-left: auto;
      }
      @include rtl {
        margin-right: auto;
      }
    }
  
    &.c-disabled {
      cursor: $cursor-disabled;
    }
    @media (hover: hover), (-ms-high-contrast: none) {
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  .c-sidebar-nav-icon {
    flex: 0 0 $sidebar-nav-icon-width;
    height: ($font-size-base * 1.25);
    font-size: ($font-size-base * 1.25);
    text-align: center;
    transition: $layout-transition-speed;
    fill: currentColor;
  
    &:first-child {
      @include ltr {
        margin-left: - $sidebar-nav-link-padding-x;
      }
      @include rtl {
        margin-right: - $sidebar-nav-link-padding-x;
      }
    }
  }
  
  .c-sidebar-nav-dropdown {
    position: relative;
    transition: background $layout-transition-speed ease-in-out;
  
    &.c-show {
      @if $enable-sidebar-nav-rounded {
        border-radius: $border-radius;
      }
      > .c-sidebar-nav-dropdown-items {
        max-height: 1500px;
      }
  
      > .c-sidebar-nav-dropdown-toggle::after {
        @include ltr {
          transform: rotate(-90deg);
        }
        @include rtl {
          transform: rotate(270deg);
        }
      }
  
      + .c-sidebar-nav-dropdown.c-show {
        margin-top: 1px;
      }
    }
  }
  
  .c-sidebar-nav-dropdown-toggle {
    @extend .c-sidebar-nav-link;
    cursor: pointer;
  
    &::after {
      display: block;
      flex: 0 8px;
      height: 8px;
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      transition: transform $layout-transition-speed;
  
      @include ltr {
        margin-left: auto;
      }
      @include rtl {
        margin-right: auto;
        transform: rotate(180deg);
      }
    }
  
    .badge {
      @include ltr {
        margin-right: $sidebar-nav-link-padding-x;
      }
      @include rtl {
        margin-left: $sidebar-nav-link-padding-x;
      }
    }
  }
  
  .c-sidebar-nav-dropdown-items {
    max-height: 0;
    padding: 0;
    overflow-y: hidden;
    list-style: none;
    transition: max-height $layout-transition-speed ease-in-out;
  
    .c-sidebar-nav-link {
      @include ltr {
        padding-left: $sidebar-nav-icon-width;
      }
      @include rtl {
        padding-right: $sidebar-nav-icon-width;
      }
  
      .c-sidebar-nav-icon {
        @include ltr {
          margin-left: - $sidebar-nav-icon-width;
        }
        @include rtl {
          margin-right: - $sidebar-nav-icon-width;
        }
      }
    }
  }
  
  .c-sidebar-nav-label {
    display: flex;
    padding: ($sidebar-nav-link-padding-y / 4) $sidebar-nav-link-padding-x;
    transition: $layout-transition-speed;
  
    &:hover {
      text-decoration: none;
    }
  
    .c-sidebar-nav-icon {
      color: $danger;
      margin-top: 1px;
    }
  }
  
  .c-sidebar-footer {
    flex: 0 0 $sidebar-footer-height;
    padding: $sidebar-footer-padding-y $sidebar-footer-padding-x;
    transition: $layout-transition-speed;
  }
  
  .c-sidebar-minimizer {
    display: flex;
    flex: 0 0 $sidebar-minimizer-height;
    justify-content: flex-end;
    width: inherit;
    padding: 0;
    cursor: pointer;
    border: 0;
  
    @include media-breakpoint-down(breakpoint-before($mobile-breakpoint)) {
      display: none;
    }
  
    &::before {
      display: block;
      width: $sidebar-minimizer-height;
      height: $sidebar-minimizer-height;
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: $sidebar-minimizer-height / 4;
      transition: $layout-transition-speed;
  
      @include rtl {
        transform: rotate(180deg);
      }
    }
  
    &:focus,
    &.c-focus {
      outline: 0;
    }
  
    .c-sidebar-right & {
      justify-content: flex-start;
      &::before {
        @include ltr {
          transform: rotate(-180deg);
        }
        @include rtl {
          transform: rotate(0deg);
        }
      }
    }
  }
  
  // Modal background
  .c-sidebar-backdrop {
    @include media-breakpoint-down(breakpoint-before($mobile-breakpoint)) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $zindex-fixed;
      width: 100vw;
      height: 100vh;
      background-color: $sidebar-backdrop-bg;
      transition: $layout-transition-speed;
  
      // Fade for backdrop
      &.c-fade { opacity: 0; }
      &.c-show { opacity: $sidebar-backdrop-opacity; }
    }
  }
  
  // Minimized Sidebar
  
  .c-sidebar-minimized {
    @include media-breakpoint-up($mobile-breakpoint) {
      z-index: $zindex-fixed + 1;
      flex: 0 0 $sidebar-minimized-width;
  
      &.c-sidebar-fixed {
        z-index: $zindex-fixed + 1;
        width: $sidebar-minimized-width;
      }
  
      &:not(.c-sidebar-right){
        @include ltr {
          margin-left: - $sidebar-minimized-width;
        }
        @include rtl {
          margin-right: - $sidebar-minimized-width;
        }
      }
      &.c-sidebar-right {
        @include ltr {
          margin-right: - $sidebar-minimized-width;
        }
        @include ltr {
          margin-left: - $sidebar-minimized-width;
        }
      }
  
      .c-sidebar-brand-full {
        display: none;
      }
  
      .c-sidebar-brand-minimized {
        display: block;
      }
  
      .c-sidebar-nav {
        padding-bottom: $sidebar-minimizer-height;
        overflow: visible;
      }
  
      .c-d-minimized-none,
      .c-sidebar-nav-divider,
      .c-sidebar-nav-label,
      .c-sidebar-nav-title,
      .c-sidebar-footer,
      .c-sidebar-form,
      .c-sidebar-header {
        height: 0;
        padding: 0;
        margin: 0;
        visibility: hidden;
        opacity: 0;
      }
  
      // Temporary fix for this issue: https://github.com/coreui/coreui-free-bootstrap-admin-template/issues/404
      // TODO: find better solution
      .c-sidebar-minimizer {
        position: fixed;
        bottom: 0;
        width: inherit;
  
        &::before {
          @include ltr {
            transform: rotate(-180deg);
          }
          @include rtl {
            transform: rotate(0deg);
          }
        }
      }
  
      &.c-sidebar-right .c-sidebar-minimizer::before {
        @include ltr {
          transform: rotate(0deg);
        }
        @include rtl {
          transform: rotate(180deg);
        }
      }
  
      &.c-sidebar-right .c-sidebar-nav {
        > .c-sidebar-nav-item:hover,
        > .c-sidebar-nav-dropdown:hover {
          @include ltr {
            margin-left: - $sidebar-width;
          }
          @include rtl {
            margin-right: - $sidebar-width;
          }
        }
      }
  
      .c-sidebar-nav-link,
      .c-sidebar-nav-dropdown-toggle {
        overflow: hidden;
        white-space: nowrap;
        border-left: 0;
  
        &:hover {
          width: $sidebar-width + $sidebar-minimized-width;
        }
      }
  
      .c-sidebar-nav-dropdown-toggle::after {
        display: none;
      }
  
      .c-sidebar-nav-dropdown-items {
        .c-sidebar-nav-link {
          width: $sidebar-width;
        }
      }
  
      // stylelint-disable-next-line no-duplicate-selectors
      .c-sidebar-nav > .c-sidebar-nav-dropdown {
        position: relative;
  
        > .c-sidebar-nav-dropdown-items {
          display: none;
  
          .c-sidebar-nav-dropdown:not(.c-show) > .c-sidebar-nav-dropdown-items {
            display: none;
          }
        }
        .c-sidebar-nav-dropdown-items {
          max-height: 1500px;
        }
  
  
        // stylelint-disable-next-line no-duplicate-selectors
        &:hover {
          width: $sidebar-width + $sidebar-minimized-width;
          overflow: visible;
  
          > .c-sidebar-nav-dropdown-items {
            position: absolute;
            display: inline;
  
            @include ltr {
              left: $sidebar-minimized-width;
            }
            @include rtl {
              right: $sidebar-minimized-width;
            }
          }
        }
      }
  
      &.c-sidebar-right > .c-sidebar-nav-dropdown:hover > .c-sidebar-nav-dropdown-items {
        @include ltr {
          left: 0;
        }
        @include rtl {
          right: 0;
        }
      }
    }
  }
  
  // Responsive behavior
  
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .c-sidebar.c-sidebar#{$infix}-show,
      .c-sidebar.c-sidebar-show {
        &:not(.c-sidebar-right){
          @include ltr {
            margin-left: 0;
          }
          @include rtl {
            margin-right: 0;
          }
          @include media-breakpoint-up($mobile-breakpoint) {
            &.c-sidebar-fixed {
              ~ .c-wrapper {
                @include ltr {
                  margin-left: $sidebar-width;
                }
                @include rtl {
                  margin-right: $sidebar-width;
                }
              }
              @each $width, $value in $sidebar-widths {
                &.c-sidebar-#{$width} {
                  ~ .c-wrapper {
                    @include ltr {
                      margin-left: $value;
                    }
                    @include rtl {
                      margin-right: $value;
                    }
                  }
                }
              }
              &.c-sidebar-minimized {
                ~ .c-wrapper {
                  @include ltr {
                    margin-left: $sidebar-minimized-width;
                  }
                  @include rtl {
                    margin-right: $sidebar-minimized-width;
                  }
                }
              }
            }
          }
        }
        &.c-sidebar-right {
          @include ltr {
            margin-right: 0;
          }
          @include rtl {
            margin-left: 0;
          }
          @include media-breakpoint-up($mobile-breakpoint) {
            &.c-sidebar-fixed {
              ~ .c-wrapper {
                @include ltr {
                  margin-right: $sidebar-width;
                }
                @include rtl {
                  margin-left: $sidebar-width;
                }
              }
              @each $width, $value in $sidebar-widths {
                &.c-sidebar-#{$width} {
                  ~ .c-wrapper {
                    @include ltr {
                      margin-right: $value;
                    }
                    @include rtl {
                      margin-left: $value;
                    }
                  }
                }
              }
              &.c-sidebar-minimized {
                ~ .c-wrapper {
                  @include ltr {
                    margin-right: $sidebar-minimized-width;
                  }
                  @include rtl {
                    margin-left: $sidebar-minimized-width;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @include themes($sidebar-theme-map, $create: append, $parent: ".c-sidebar") {
    color: themes-get-value("sidebar-color");
    background: themes-get-value("sidebar-bg");
  
    @include borders(themes-get-value("sidebar-borders"));
  
    @include ltr {
      &.c-sidebar-right {
        @include borders(themes-get-value("sidebar-borders"),true);
      }
    }
  
    @include rtl {
      @include borders(themes-get-value("sidebar-borders"),true);
      &.c-sidebar-right {
        border: 0;
        @include borders(themes-get-value("sidebar-borders"));
      }
    }
  
    .c-sidebar-close {
      color: themes-get-value("sidebar-color");
    }
  
    .c-sidebar-brand {
      color: $primary-200;
      background: rgba(0, 0, 0, 0.4);
    }
  
    .c-sidebar-header {
      background: themes-get-value("sidebar-header-bg");
    }
  
    .c-sidebar-form .c-form-control {
      color: themes-get-value("sidebar-form-color");
      background: themes-get-value("sidebar-form-bg");
      border: themes-get-value("sidebar-form-border");
  
      &::placeholder {
        color: themes-get-value("sidebar-form-placeholder-color");
      }
    }
  
    .c-sidebar-nav-title {
      color: $gray-700;
    }
  
    .c-sidebar-nav-link {
      color: $danger;
      font-weight: 500;
      background: themes-get-value("sidebar-nav-link-bg");
      @include borders(themes-get-value("sidebar-nav-link-borders"));
  
      .c-sidebar-nav-icon {
        color: $danger;
      }
  
      &.c-active {
        color: $danger;
        background: $primary-300;
        @include borders(themes-get-value("sidebar-nav-link-active-borders"));
  
        .c-sidebar-nav-icon {
          color: $danger;
        }
      }
      @media (hover: hover), (-ms-high-contrast: none) {
        &:hover {
          color: themes-get-value("sidebar-nav-link-hover-color");
          background: themes-get-value("sidebar-nav-link-hover-bg");
          @include borders(themes-get-value("sidebar-nav-link-hover-borders"));
  
          .c-sidebar-nav-icon {
            color: themes-get-value("sidebar-nav-link-hover-icon-color");
          }
  
          &.c-sidebar-nav-dropdown-toggle::after {
            background-image: themes-get-value("sidebar-nav-dropdown-indicator-hover");
          }
        }
      }
  
      &.c-disabled {
        color: themes-get-value("sidebar-nav-link-disabled-color");
        background: transparent;
        @include borders(themes-get-value("sidebar-nav-link-disabled-borders"));
  
        .c-sidebar-nav-icon {
          color: themes-get-value("sidebar-nav-link-disabled-icon-color");
        }
  
        &:hover {
          color: themes-get-value("sidebar-nav-link-disabled-color");
          @include borders(themes-get-value("sidebar-nav-link-hover-borders"));
  
          .c-sidebar-nav-icon {
            color: themes-get-value("sidebar-nav-link-disabled-icon-color");
          }
  
          &.c-sidebar-nav-dropdown-toggle::after {
            background-image: themes-get-value("sidebar-nav-dropdown-indicator-hover");
          }
        }
      }
    }
    .c-sidebar-nav-dropdown-toggle {
      position: relative;
  
      &::after {
        background-image: themes-get-value("sidebar-nav-dropdown-indicator");
      }
    }
  
    .c-sidebar-nav-dropdown.c-show {
      background: themes-get-value("sidebar-nav-dropdown-bg");
      @include borders(themes-get-value("sidebar-nav-dropdown-borders"));
  
      .c-sidebar-nav-link {
        color: themes-get-value("sidebar-nav-dropdown-color");
  
        &.c-disabled {
          color: themes-get-value("sidebar-nav-link-disabled-color");
          background: transparent;
  
          &:hover {
            color: themes-get-value("sidebar-nav-link-disabled-color");
  
            .c-sidebar-nav-icon {
              color: themes-get-value("sidebar-nav-link-disabled-icon-color");
            }
          }
        }
      }
    }
  
    .c-sidebar-nav-label {
      color: themes-get-value("sidebar-nav-title-color");
  
      &:hover {
        color: themes-get-value("sidebar-color");
      }
  
      .c-sidebar-nav-icon {
        color: $danger;
      }
    }
  
    @if (lightness(themes-get-value("sidebar-bg")) < 40) {
      .c-progress {
        background-color: lighten(themes-get-value("sidebar-bg"), 15%) !important;
      }
    }
  
    .c-sidebar-footer {
      background: themes-get-value("sidebar-footer-bg");
      @include borders(themes-get-value("sidebar-footer-borders"));
    }
  
    .c-sidebar-minimizer {
      background-color: $primary-200;
      @include borders(themes-get-value("sidebar-minimizer-borders"));
  
      &::before {
        background-image: themes-get-value("sidebar-minimizer-indicator");
      }
  
      &:focus,
      &.c-focus {
        outline: 0;
      }
  
      &:hover {
        background-color: themes-get-value("sidebar-minimizer-hover-bg");
        &::before {
          background-image: themes-get-value("sidebar-minimizer-hover-indicator");
        }
      }
    }
    &.c-sidebar-minimized {
  
      .c-sidebar-nav-item {
  
        &:hover {
  
          > .c-sidebar-nav-link, {
            background: /themes-get-value("sidebar-nav-link-hover-bg");
  
            .c-sidebar-nav-icon {
              color: themes-get-value("sidebar-nav-link-hover-color");
            }
  
            &.c-disabled {
              background: themes-get-value("sidebar-nav-link-disabled-bg");
  
              .c-sidebar-nav-icon {
                color: themes-get-value("sidebar-nav-link-disabled-icon-color");
              }
            }
          }
        }
      }
  
      .c-sidebar-nav {
        > .c-sidebar-nav-dropdown {
          > .c-sidebar-nav-dropdown-items {
            background: themes-get-value("sidebar-bg");
          }
  
          &:hover {
            background: themes-get-value("sidebar-nav-link-hover-bg");
          }
        }
      }
    }
  }
  
  // stylelint-disable-next-line no-duplicate-selectors
  .c-sidebar {
    background-color: $primary-200;
    @include theme-variant() {
      .c-sidebar-nav-link {
        &.c-sidebar-nav-link-#{$color} {
          background: $value;
          .c-sidebar-nav-icon {
            color: rgba(255, 255, 255, .7);
          }
          &:hover {
            background: darken($value, 5%);
            .c-sidebar-nav-icon {
              color: $danger;
            }
          }
        }
      }
    }
  }
  