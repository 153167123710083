// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";
@import "node_modules/open-iconic/font/css/open-iconic-bootstrap.scss";

// If you want to add something do it here
@import "sidebar";
@import "custom";
