// Variable overrides
//
// CoreUI default colors
//

$white: #fff;

$black:  #000015 ;

$gray-base:  #4D4D4D ;
$gray-100:   #f2f2f2 ;
$gray-200:   #d9d9d9 ;
$gray-300:   #bfbfbf ;
$gray-400:   #a6a6a6 ;
$gray-500:   #8c8c8c ;
$gray-600:   #737373 ;
$gray-700:   #595959 ;
$gray-800:   #404040 ;
$gray-900:   #262626 ;

$grays: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100":  $gray-100,
    "200":  $gray-200,
    "300":  $gray-300,
    "400":  $gray-400,
    "500":  $gray-500,
    "600":  $gray-600,
    "700":  $gray-700,
    "800":  $gray-800,
    "900":  $gray-900
  ),
  $grays
);

$primary-base:  #677998 ;
$primary-100:   #f0f2f5 ;
$primary-200:   #d1d7e0 ;
$primary-300:   #b3bccb ;
$primary-400:   #95a1b7 ;
$primary-500:   #7686a2 ;
$primary-600:   #5d6d89 ;
$primary-700:   #48556a ;
$primary-800:   #343d4c ;
$primary-900:   #1f242e ;

$primary-dark:  #0a0c0f ;
$primary:       #677998 ;
$primary-50:    #d1d7e0 ;
$primary-25:    #f0f2f5 ; 


$secondary-base:  #4D4D4D ;

$secondary-100:   #f2f2f2 ;
$secondary-200:   #d9d9d9 ;
$secondary-300:   #bfbfbf ;
$secondary-400:   #a6a6a6 ;
$secondary-500:   #8c8c8c ;
$secondary-600:   #737373 ; 
$secondary-700:   #595959 ;
$secondary-800:   #404040 ;
$secondary-900:   #262626  ;

$secondary-dark:  #0d0d0d ;
$secondary:       #4D4D4D ;
$secondary-50:    #d9d9d9 ;
$secondary-25:    #f2f2f2 ;


$danger-dark:  #140506 ;
$danger:       #AE2F31 ;
$danger-50:    #f2a9a9 ;
$danger-25:    #f9d4d4 ;

$info-dark:  #085f6e ;
$info:       #80B8C2 ;
$info-50:    #8ccbd6 ;
$info-25:    #abd7df ;

$success-dark:  #2f9290 ;
$success:       #208f8d ;
$success-50:    #50a5a4 ;
$success-25:    #96e4e2 ;

$warning-dark:  #f6960b ;
$warning:       #FFE633 ;
$warning-50:    #fcd88a ;
$warning-25:    #feecc5 ;

$light:  $gray-100 ;
$dark:   $gray-800 ;

$pink:  #e83e8c ;

$theme-colors: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary-25,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:  8% ;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 ;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:   $gray-900 ;
$yiq-text-light:  $white ;

$yiq-theme-map: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$yiq-theme-map: map-merge(
  (
    default: (
      "yiq-text-dark":  $yiq-text-dark,
      "yiq-text-light":  $yiq-text-light
    )
  ),
  $yiq-theme-map
);

// TODO: Move to better place
// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
  ("(","%28"),
  (")","%29"),
) ;


.bg-primary {
    background-color: $primary-200 !important;
}

$sidebar-dark-nav-link-hover-bg: $primary-300;
$sidebar-light-nav-link-hover-bg: $primary-300;
$sidebar-dark-nav-link-active-bg: $primary-300;

// $font-size-base:  .656rem;
// $font-size-lg:    $font-size-base * 1;
// $font-size-sm:    $font-size-base * .656;

